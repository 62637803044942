import "regenerator-runtime";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

/**
 * Navigation
 *
 * @type {React.LazyExoticComponent<React.ComponentClass<any> | React.FunctionComponent<any>>}
 */
const LazyNavigationApp = React.lazy(() =>
    import(
        /* webpackChunkName: "valu-navigation" */ "./lib/ValuNavigation/ValuNavigation"
    ),
);

// const navigationAppContainer = document.getElementById("valu-navigation");

// if (LazyNavigationApp && !navigationAppContainer) {
//     ReactDOM.render(
//         <>
//             <Loader>
//                 <LazyNavigationApp />
//             </Loader>
//         </>,
//         navigationAppContainer,
//     );
// }

export function Loader(props) {
    return (
        <Suspense
            fallback={
                /* TODO: Add spinner */
                ""
            }
        >
            {props.children}
        </Suspense>
    );
}

/**
 * Mechanics Archive in archive-contact.php
 *
 * @type {React.LazyExoticComponent<React.ComponentClass<any> | React.FunctionComponent<any>>}
 */
const LazyMechanicsArchiveApp = React.lazy(() =>
    import(
        /* webpackChunkName: "mechanics-archive-app" */ "./lib/JsonArchive/Mechanics/MechanicsArchiveApp"
    ),
);

const mechanicsArchiveAppContainer = document.getElementById(
    "mechanics-archive-app",
);

if (LazyMechanicsArchiveApp && mechanicsArchiveAppContainer) {
    ReactDOM.render(
        <Router>
            <Loader>
                <LazyMechanicsArchiveApp />
            </Loader>
        </Router>,
        mechanicsArchiveAppContainer,
    );
}

/**
 * To date archive in archive-to-date.php
 *
 * @type {React.LazyExoticComponent<React.ComponentClass<any> | React.FunctionComponent<any>>}
 */
const LazyToDateArchiveApp = React.lazy(() =>
    import(
        /* webpackChunkName: "to-date-archive-app" */ "./lib/JsonArchive/ToDate/ToDateArchiveApp"
    ),
);

const toDateArchiveAppContainer = document.getElementById(
    "to-date-archive-app",
);

if (LazyToDateArchiveApp && toDateArchiveAppContainer) {
    ReactDOM.render(
        <Router>
            <Loader>
                <LazyToDateArchiveApp />
            </Loader>
        </Router>,
        toDateArchiveAppContainer,
    );
}
